<template>
  <div class="content-wrapper">
    <bo-page-header />
    <div class="content pt-0">
      <b-card body-class="p-0">
        <b-row>
          <b-col cols-md="6" cols="6">
            <div class="wrap_title">
              <h3>Topik: </h3>
              <p>Rencana Anggaran Alutista</p>
            </div>
            <b-row>
              <b-col cols-md="4" cols="4">
                <div class="wrap_statistik">
                  <h3 class="text-indigo-400">15</h3>
                  <p>Kata kunci utama</p>
                </div>
              </b-col>
              <b-col cols-md="4" cols="4">
                <div class="wrap_statistik">
                  <h3 class="text-info">5230</h3>
                  <p>Sumber Website</p>
                </div>
              </b-col>
              <b-col cols-md="4" cols="4">
                <div class="wrap_statistik" style="border-right:1px solid transparent;">
                  <h3 class="text-success">4</h3>
                  <p>Platform Social Media</p>
                </div>
              </b-col>
            </b-row>
          </b-col>

          <b-col class="bor_left" cols-md="6" cols="6">
            <div class="wrap_info_ds">
              <h3>Awal Penggalian Data: </h3>
              <p>01 Agustus 2021</p>
            </div>

            <div class="wrap_info_ds">
              <h3>Durasi Penggalian Data: </h3>
              <p>1 Tahun</p>
            </div>
          </b-col>
        </b-row>
      </b-card>
      <!-- <div class="card_resume">
        <b-row cols="1" cols-md="5">
          <b-col>
            <div class="d-flex align-items-center">
              <div class="mr-3">
                <a href="#" class="wrap_ic bg_white">
                  <i class="icon-bubble2"></i>
                </a>
              </div>
              <div class="static_wrap">
                <h3>529</h3>
                <p>Post</p>
              </div>
            </div>
          </b-col>
          <b-col>
            <div class="d-flex align-items-center">
              <div class="mr-3">
                <a href="#" class="wrap_ic bg_white">
                  <i class="icon-users2"></i>
                </a>
              </div>
              <div class="static_wrap">
                <h3>297</h3>
                <p>Users</p>
              </div>
            </div>
          </b-col>
          <b-col>
            <div class="d-flex align-items-center">
              <div class="mr-3">
                <a href="#" class="wrap_ic bg_white">
                  <i class="icon-heart6"></i>
                </a>
              </div>
              <div class="static_wrap">
                <h3>3,802</h3>
                <p>Engagement</p>
              </div>
            </div>
          </b-col>
          <b-col>
            <div class="d-flex align-items-center">
              <div class="mr-3">
                <a href="#" class="wrap_ic bg_white">
                  <i class="icon-megaphone"></i>
                </a>
              </div>
              <div class="static_wrap">
                <h3>1,757,213</h3>
                <p>Reach</p>
              </div>
            </div>
          </b-col>
          <b-col>
            <div class="d-flex align-items-center">
              <div class="mr-3">
                <a href="#" class="wrap_ic bg_white">
                  <i class="icon-mic2"></i>
                </a>
              </div>
              <div class="static_wrap">
                <h3>5,035,711</h3>
                <p>Impression</p>
              </div>
            </div>
          </b-col>
        </b-row>
      </div> -->
      <!-- <b-card body-class="d-md-flex align-items-md-center justify-content-md-between flex-md-wrap">
        <div class="d-flex align-items-center mb-3 mb-md-0">
          <v-chart class="sentiment-pie" :option="sentimentPieOptions" />
          <div class="ml-3">
            <h5 class="font-weight-bold mb-0 text-info">POSITIF <span class="text-info font-size-sm font-weight-normal">
                (+2.9%)</span></h5>
            <span class="badge badge-mark border-info mr-1"></span> <span class="text-muted">Jun 16, 10:00 am</span>
          </div>
        </div>
        <div class="d-flex align-items-center mb-3 mb-md-0">
          <b-nav pills align="end" class="mb-0">
            <b-nav-item active-class="active bg-indigo-400">Days</b-nav-item>
            <b-nav-item active-class="active bg-indigo-400">Week</b-nav-item>
            <b-nav-item active-class="active bg-indigo-400">Month</b-nav-item>
          </b-nav>
          <div id="tickets-status"></div>
          <div class="ml-3">
            <b-input-group>
              <template #prepend>
                <span class="input-group-text"><i class="icon-calendar22"></i></span>
              </template>
              <date-range-picker class="form-control" control-container-class="" opens="left" append-to-body
                :date-range="dateRange" style="min-width: 180px" />
            </b-input-group>
          </div>
        </div>
      </b-card> -->

      <b-row>
        <b-col md="6">
          <b-card class="mb-0" body-class="d-md-flex align-items-md-center justify-content-md-between flex-md-wrap">
            <template #header>
              <b-card-title title-tag="h6" class="font-weight-bold">Tren Sentimen Minggu Ini</b-card-title>
            </template>
            <div class="chart-container">
              <v-chart class="chart chart-h-320" :option="sentimentTrendsOptions" />
            </div>
          </b-card>
          <b-card class="border-top" body-class="p-0 text-center">
            <b-row>
              <b-col cols-md="12" cols="12">
                <div class="wrap_statistik" style="border-bottom:1px solid #ccc; border-right:1px solid transparent;">
                  <h3>1230</h3>
                  <p>Data</p>
                </div>
                <b-row class="text-center">
                  <b-col cols-md="4" cols="4">
                    <div class="wrap_statistik" style="height:89px;">
                      <h3 class="txt_positif">392</h3>
                      <p>Sentimen Positif</p>
                    </div>
                  </b-col>
                  <b-col cols-md="4" cols="4">
                    <div class="wrap_statistik">
                      <h3 class="txt_negative">201</h3>
                      <p>Sentimen Negatif</p>
                    </div>
                  </b-col>
                  <b-col cols-md="4" cols="4">
                    <div class="wrap_statistik" style="border-right:1px solid transparent;">
                      <h3 class="txt_neutral">637</h3>
                      <p>Sentimen Netral</p>
                    </div>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col md="6">
          <b-card class="mb-0" body-class="d-md-flex align-items-md-center justify-content-md-between flex-md-wrap">
            <template #header>
              <b-card-title title-tag="h6" class="font-weight-bold">Aksi Tangkal Isu Minggu Ini</b-card-title>
            </template>
            <div class="chart-container">
              <v-chart class="chart chart-h-320" :option="weekCounterOptions" />
            </div>
          </b-card>
          <b-card class="border-top" body-class="p-0 text-center">
            <b-row>
              <b-col md="12" cols="12">
                <div class="wrap_statistik" style="border-bottom:1px solid #ccc; border-right:1px solid transparent;">
                  <h3>56</h3>
                  <p>Konten Counter</p>
                </div>
                <b-row class="text-center">
                  <b-col md="6" cols="6">
                    <div class="wrap_statistik" style="height:89px;">
                      <h3 class="txt_positif">40</h3>
                      <p>Konten Website</p>
                    </div>
                  </b-col>
                  <b-col md="6" cols="6">
                    <div class="wrap_statistik">
                      <h3 class="text-info">16</h3>
                      <p>Konten Sosial Media</p>
                    </div>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-card>
        </b-col>

      </b-row>
      <b-row cols="1" cols-md="2">
        <b-col>
          <b-card>
            <template #header>
              <b-card-title title-tag="h6" class="font-weight-bold">Performa Aktivitas Media Minggu Ini</b-card-title>
              <span class="f-13">Terhubung kata kunci.</span>
            </template>
            <div class="chart-container">
              <v-chart class="chart has-fixed-height" :option="mentionSourceOptions" />
            </div>
            <template #footer>
              <div class="text-right">
                <a href="#">Lihat Lebih Banyak <i class="icon-arrow-right7"></i></a>
              </div>
            </template>
          </b-card>
        </b-col>
        <b-col>
          <b-card>
            <template #header>
              <b-card-title title-tag="h6" class="font-weight-bold">Performa Tangkapan Sentimen Minggu Ini</b-card-title>
              <span class="f-13">Terhubung kata kunci.</span>
            </template>
            <div class="chart-container">
              <v-chart class="chart has-fixed-height" :option="sentimentCompositionOptions" />
            </div>
            <template #footer>
              <div class="text-right">
                <a href="#">Lihat Lebih Banyak <i class="icon-arrow-right7"></i></a>
              </div>
            </template>
          </b-card>
        </b-col>
        <b-col>
          <b-card>
            <template #header>
              <b-card-title title-tag="h6" class="font-weight-bold">Daftar Media Website Teraktif Minggu Ini</b-card-title>
              <span class="f-13">Terhubung kata kunci.</span>
            </template>
            <div class="chart-container">
              <v-chart class="chart chart-h-320" :option="mostActiveWebsiteOptions" />
            </div>
            <template #footer>
              <div class="text-right">
                <a href="#">Lihat Lebih Banyak <i class="icon-arrow-right7"></i></a>
              </div>
            </template>
          </b-card>
        </b-col>
        <b-col>
          <b-card>
            <template #header>
              <b-card-title title-tag="h6" class="font-weight-bold">Daftar Media Sosial Teraktif Minggu Ini</b-card-title>
              <span class="f-13">Terhubung kata kunci.</span>
            </template>
            <div class="chart-container">
              <v-chart class="chart chart-h-320" :option="mostActiveSocmedOptions" />
            </div>
            <template #footer>
              <div class="text-right">
                <a href="#">Lihat Lebih Banyak <i class="icon-arrow-right7"></i></a>
              </div>
            </template>
          </b-card>
        </b-col>
        <b-col>
          <b-card>
            <template #header>
              <b-card-title title-tag="h6" class="font-weight-bold">Website Paling Berpengaruh Minggu Ini</b-card-title>
              <span class="f-13">Terhubung kata kunci.</span>
            </template>
            <div class="chart-container">
              <v-chart class="chart chart-h-320" :option="mostInfluentialWebsiteOptions" />
            </div>
            <template #footer>
              <div class="text-right">
                <a href="#">Lihat Lebih Banyak <i class="icon-arrow-right7"></i></a>
              </div>
            </template>
          </b-card>
        </b-col><b-col cols-md="6" cols="6">
          <b-card body-class="d-md-flex align-items-md-center justify-content-md-between flex-md-wrap">
            <template #header>
              <b-card-title title-tag="h6" class="font-weight-bold">Akun Sosial Media Paling Berpengaruh Minggu Ini</b-card-title>
              <span class="f-13">Terhubung kata kunci.</span>
            </template>
            <div class="card_mention">
              <b-row>
                <b-col cols-md="8" cols="8">
                  <div class="d-flex align-items-center">
                    <div class="mr-3">
                      <a href="#" class="btn bg_tw  rounded-round btn-icon btn-sm">
                        <i class="icon-twitter"></i>
                      </a>
                    </div>
                    <div>
                      <a href="#" class="text-default font-weight-bold letter-icon-title">@djokoAdv</a>
                      <div class="text-muted font-size-sm"><span class="badge badge-mark border-blue mr-1"></span>
                        Active</div>
                    </div>
                  </div>
                </b-col>
                <b-col cols-md="4" cols="4">
                  <div class="card_content">
                    <p>
                      197.715 Pengikut
                    </p>
                  </div>
                </b-col>
              </b-row>
            </div>
             <div class="card_mention">
              <b-row>
                <b-col cols-md="8" cols="8">
                  <div class="d-flex align-items-center">
                    <div class="mr-3">
                      <a href="#" class="btn bg_ig  rounded-round btn-icon btn-sm">
                        <i class="icon-instagram"></i>
                      </a>
                    </div>
                    <div>
                      <a href="#" class="text-default font-weight-bold letter-icon-title">@islamtoday.id</a>
                      <div class="text-muted font-size-sm"><span class="badge badge-mark border-blue mr-1"></span>
                        Active</div>
                    </div>
                  </div>
                </b-col>
                <b-col cols-md="4" cols="4">
                  <div class="card_content">
                    <p>
                      1990 Followers
                    </p>
                  </div>
                </b-col>
              </b-row>
            </div>
            <div class="card_mention">
              <b-row>
                <b-col cols-md="8" cols="8">
                  <div class="d-flex align-items-center">
                    <div class="mr-3">
                      <a href="#" class="btn bg_tw  rounded-round btn-icon btn-sm">
                        <i class="icon-twitter"></i>
                      </a>
                    </div>
                    <div>
                      <a href="#" class="text-default font-weight-bold letter-icon-title">@kumparanplus</a>
                      <div class="text-muted font-size-sm"><span class="badge badge-mark border-blue mr-1"></span>
                        Active</div>
                    </div>
                  </div>
                </b-col>
                <b-col cols-md="4" cols="4">
                  <div class="card_content">
                    <p>
                      1990 Followers
                    </p>
                  </div>
                </b-col>
              </b-row>
            </div>
            <div class="card_mention">
              <b-row>
                <b-col cols-md="8" cols="8">
                  <div class="d-flex align-items-center">
                    <div class="mr-3">
                      <a href="#" class="btn bg_tw  rounded-round btn-icon btn-sm">
                        <i class="icon-twitter"></i>
                      </a>
                    </div>
                    <div>
                      <a href="#" class="text-default font-weight-bold letter-icon-title">@okezonenews</a>
                      <div class="text-muted font-size-sm"><span class="badge badge-mark border-blue mr-1"></span>
                        Active</div>
                    </div>
                  </div>
                </b-col>
                <b-col cols-md="4" cols="4">
                  <div class="card_content">
                    <p>
                      1990 Followers
                    </p>
                  </div>
                </b-col>
              </b-row>
            </div>
            <template #footer>
              <div class="text-right">
                <a href="#">Lihat Lebih Banyak <i class="icon-arrow-right7"></i></a>
              </div>
            </template>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols-md="12" cols="12">
          <b-card body-class="p-0 d-md-flex align-items-md-center justify-content-md-between flex-md-wrap">
            <template #header>
              <b-card-title title-tag="h6" class="font-weight-bold">Riwayat Kolaborasi Tim</b-card-title>
            </template>
            <div class="table-responsive">
              <table class="table table-bordered table-striped">
                <thead>
                  <tr>
                    <th>Waktu</th>
                    <th>Konten</th>
                    <th>Aksi </th>
                    <th>Petugas</th>
                    <th>Catatan</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>15 Agustus 21, 14:20 WIB</td>
                    <td rowspan="2"><a href="" class="font-weight-bold mb-0">Pentingnya Tambahan 6 Jet Latih T-50i Golden Eagle untu... </a></td>
                    <td>Koreksi Sentimen</td>
                    <td> AB</td>
                    <td>Sentimen netral</td>
                  </tr>
                  <tr>
                    <td>15 Agustus 21, 16:47 WIB</td>
                    <td>Approval Sentimen</td>
                    <td>HS</td>
                    <td>Sentimen positif</td>
                  </tr>
                  <tr>
                    <td>14 Agustus 21, 10:30 WIB</td>
                    <td rowspan="2"><a href="" class="font-weight-bold mb-0">Prabowo Tawarkan Anggaran Pertahanan Dialihkan untuk Berantas Covid-19, Jokowi Beri Jawaban... </a></td>
                    <td>Koreksi Sentimen</td>
                    <td> TA</td>
                    <td>Sentimen netral</td>
                  </tr>
                  <tr>
                    <td>14 Agustus 21, 14:27 WIB</td>
                    <td>Approval Sentimen</td>
                    <td>HS</td>
                    <td>Sentimen positif</td>
                  </tr>
                  <tr>
                    <td>12 Agustus 21, 12:27 WIB</td>
                    <td rowspan="2"><a href="" class="font-weight-bold mb-0">Kemenhan Akui Prabowo Kenal Beberapa Orang PT TMI... </a></td>
                    <td>Koreksi Sentimen</td>
                    <td> AB</td>
                    <td>Sentimen netral</td>
                  </tr>
                  <tr>
                    <td>12 Agustus 21, 14:39 WIB</td>
                    <td>Approval Sentimen</td>
                    <td>HS</td>
                    <td>Sentimen positif</td>
                  </tr>
                  <tr>
                    <td>11 Agustus 21, 11:49 WIB</td>
                    <td rowspan="2"><a href="" class="font-weight-bold mb-0">Pengamat Kritik Prabowo soal Anggaran Pertahanan Minim... </a></td>
                    <td>Koreksi Sentimen</td>
                    <td>FN</td>
                    <td>Sentimen netral</td>
                  </tr>
                  <tr>
                    <td>11 Agustus 21, 13:07 WIB</td>
                    <td>Approval Sentimen</td>
                    <td>HS</td>
                    <td>Sentimen negatif</td>
                  </tr>
                  <tr>
                    <td>09 Agustus 21, 15:17 WIB</td>
                    <td rowspan="2"><a href="" class="font-weight-bold mb-0">Polemik Anggaran Jumbo Kementerian Prabowo... </a></td>
                    <td>Koreksi Sentimen</td>
                    <td> AB</td>
                    <td>Sentimen netral</td>
                  </tr>
                  <tr>
                    <td>09 Agustus 21, 17:27 WIB</td>
                    <td>Approval Sentimen</td>
                    <td>HS</td>
                    <td>Sentimen negatif</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <template #footer>
              <div class="text-right">
                <a href="#">Lihat Lebih Banyak <i class="icon-arrow-right7"></i></a>
              </div>
            </template>
          </b-card>
        </b-col>
      </b-row>

      <!-- <b-card>
        <template #header>
          <b-card-title title-tag="h6" class="font-weight-bold">Mention Summary</b-card-title>
        </template>
        <div class="chart-container">
          <v-chart :option="mentionSummaryOptions" class="chart has-fixed-height" />
        </div>
      </b-card>

      <b-row cols-md="2" cols="1">
        <b-col>
          <b-card>
            <template #header>
              <b-card-title title-tag="h6" class="font-weight-bold">Mention by Location</b-card-title>
            </template>
            <div class="chart-container">
              <v-chart class="chart has-fixed-height" :option="mentionLocationOptions" />
            </div>
          </b-card>
        </b-col>
        <b-col>
          <b-card>
            <template #header>
              <b-card-title title-tag="h6" class="font-weight-bold">Mention by Source</b-card-title>
            </template>
            <div class="chart-container">
              <v-chart class="chart has-fixed-height" :option="mentionSourceOptions" />
            </div>
          </b-card>
        </b-col>
      </b-row>

      <b-card footer-class="p-0">
        <template #header>
          <b-card-title title-tag="h6" class="font-weight-bold">Social Media Follower</b-card-title>
        </template>
        <div class="chart-container">
          <v-chart class="chart has-fixed-height" :option="socmedFollowerOptions" />
        </div>
        <template #footer>
          <b-row cols="1" cols-md="3">
            <b-col>
              <div class="wrap_sosmed_view">
                <div class="ic_sosmed bg_ig "><i class="icon-instagram"></i></div>
                <div class="wrap_text_prev">
                  <div class="row">
                    <div class="col-md-4">
                      <h2>Total</h2>
                      <p>100</p>
                    </div>
                    <div class="col-md-4">
                      <h2>Gain</h2>
                      <p>100</p>
                    </div>
                    <div class="col-md-4">
                      <h2>Lost</h2>
                      <p>100</p>
                    </div>
                  </div>
                </div>
              </div>
            </b-col>
            <b-col>
              <div class="wrap_sosmed_view">
                <div class="ic_sosmed bg_tw "><i class="icon-twitter"></i></div>
                <div class="wrap_text_prev">
                  <div class="row">
                    <div class="col-md-4">
                      <h2>Total</h2>
                      <p>100</p>
                    </div>
                    <div class="col-md-4">
                      <h2>Gain</h2>
                      <p>100</p>
                    </div>
                    <div class="col-md-4">
                      <h2>Lost</h2>
                      <p>100</p>
                    </div>
                  </div>
                </div>
              </div>
            </b-col>
            <b-col>
              <div class="wrap_sosmed_view noborder_r">
                <div class="ic_sosmed bg_fb"><i class="icon-facebook"></i></div>
                <div class="wrap_text_prev">
                  <div class="row">
                    <div class="col-md-4">
                      <h2>Total</h2>
                      <p>100</p>
                    </div>
                    <div class="col-md-4">
                      <h2>Gain</h2>
                      <p>100</p>
                    </div>
                    <div class="col-md-4">
                      <h2>Lost</h2>
                      <p>100</p>
                    </div>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
        </template>
      </b-card>

      <b-card no-body>
        <template #header>
          <b-card-title title-tag="h6" class="font-weight-bold">Social Media Engagement</b-card-title>
        </template>
        <b-table :fields="socmedEngagementFields" :items="socmedEngagementDatas" bordered responsive :per-page="5"
          :current-page="socmedEngagementPage">
          <template #cell(num)="data">
            {{ data.index += 1 + (socmedEngagementPage - 1)*5  }}
          </template>
        </b-table>
      </b-card> -->
    </div>
    <bo-footer />
  </div>
</template>
<script>
  import chartDatas from '@/dummies/chartDatas'
  import tableDatas from '@/dummies/tableDatas'
  import {
    use
  } from "echarts/core";
  import {
    CanvasRenderer
  } from "echarts/renderers";
  import {
    PieChart,
    LineChart,
    BarChart,
  } from "echarts/charts";
  import {
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    GridComponent,
    DataZoomComponent,
  } from "echarts/components";
  import VChart from 'vue-echarts'
  import GlobalVue from '../../libs/Global.vue';

  use([
    CanvasRenderer,
    PieChart,
    LineChart,
    BarChart,
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    GridComponent,
    DataZoomComponent,
  ])

  export default {
    extends: GlobalVue,
    mounted() {
      this.loadSocialEngagement()
      this.loadClosing()
    },
    components: {
      VChart,
    },
    data() {
      return {
        sentimentPieOptions: chartDatas.sentimentPieOptions,
        mentionSummaryOptions: chartDatas.mentionSummaryOptions,
        mentionLocationOptions: chartDatas.mentionLocationOptions,
        mentionSourceOptions: chartDatas.mentionSourceOptions,
        socmedFollowerOptions: chartDatas.socmedFollowerOptions,
        leadsOptions: chartDatas.leadsOptions,
        sentimentCompositionOptions: chartDatas.sentimentCompositionOptions,
        mostActiveSocmedOptions: chartDatas.mostActiveSocmedOptions,
        mostActiveWebsiteOptions: chartDatas.mostActiveWebsiteOptions,
        sentimentTrendsOptions: chartDatas.sentimentTrendsOptions,
        weekCounterOptions: chartDatas.weekCounterOptions,
        mostInfluentialWebsiteOptions: chartDatas.mostInfluentialWebsiteOptions,
        dateRange: {},
        socmedEngagementFields: [],
        socmedEngagementDatas: [],
        socmedEngagementPage: 1,
        closingFields: [],
        closingDatas: [],
        closingPage: 1,
        lead1: ''
      }
    },
    methods: {
      loadSocialEngagement() {
        tableDatas.socmedEngagementFields.forEach(el => {
          this.socmedEngagementFields.push(el)
        })
        tableDatas.socmedEngagementDatas.forEach(el => {
          this.socmedEngagementDatas.push(el)
        })
        // const total = tableDatas.socmedEngagementDatas.reduce((a, b) => a + b.value, 0)
        // console.log(total)
      },
    }
  }
</script>